<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card card-custom gutter-b card-stretch">
        <div class="card-body d-flex flex-column p-0">
          <div class="flex-grow-1 card-spacer-x">
            <div class="row">
              <div class="col-xl-2">
                <ul class="nav flex-column nav-pills pt-3">
                  <li class="nav-item">
                    <router-link to="/notice">
                      <a :class="menuFlag == '1' ? 'nav-link active' : 'nav-link'" href="#" v-on:click="setMenuFlag(1)">通知公告</a>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/update">
                      <a :class="menuFlag == '2' ? 'nav-link active' : 'nav-link'" href="#" v-on:click="setMenuFlag(2)">系统更新</a>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/wx">
                      <a :class="menuFlag == '3' ? 'nav-link active' : 'nav-link'" href="#" v-on:click="setMenuFlag(3)">关注微信公众号</a>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/accredit">
                      <a :class="menuFlag == '4' ? 'nav-link active' : 'nav-link'" href="#" v-on:click="setMenuFlag(4)">系统授权</a>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-xl-10 border-left">
                <router-view @send="receiveFromChild" :user="user"></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    }
  },
  data(){
    return{
      menuFlag: "1"
    }
  },
  methods:{
    receiveFromChild(value){
      this.menuFlag = value;
    },
    setMenuFlag(flag){
      this.menuFlag = flag;
    }
  }
}
</script>